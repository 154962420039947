/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { getGlobal, registerGlobal, unregisterGlobal } from '../internal/global-utils';
import { ProxyTracerProvider } from '../trace/ProxyTracerProvider';
import { isSpanContextValid, wrapSpanContext } from '../trace/spancontext-utils';
import { deleteSpan, getActiveSpan, getSpan, getSpanContext, setSpan, setSpanContext } from '../trace/context-utils';
import { DiagAPI } from './diag';
var API_NAME = 'trace';
/**
 * Singleton object which represents the entry point to the OpenTelemetry Tracing API
 */
var TraceAPI = /** @class */function () {
  /** Empty private constructor prevents end users from constructing a new instance of the API */
  function TraceAPI() {
    this._proxyTracerProvider = new ProxyTracerProvider();
    this.wrapSpanContext = wrapSpanContext;
    this.isSpanContextValid = isSpanContextValid;
    this.deleteSpan = deleteSpan;
    this.getSpan = getSpan;
    this.getActiveSpan = getActiveSpan;
    this.getSpanContext = getSpanContext;
    this.setSpan = setSpan;
    this.setSpanContext = setSpanContext;
  }
  /** Get the singleton instance of the Trace API */
  TraceAPI.getInstance = function () {
    if (!this._instance) {
      this._instance = new TraceAPI();
    }
    return this._instance;
  };
  /**
   * Set the current global tracer.
   *
   * @returns true if the tracer provider was successfully registered, else false
   */
  TraceAPI.prototype.setGlobalTracerProvider = function (provider) {
    var success = registerGlobal(API_NAME, this._proxyTracerProvider, DiagAPI.instance());
    if (success) {
      this._proxyTracerProvider.setDelegate(provider);
    }
    return success;
  };
  /**
   * Returns the global tracer provider.
   */
  TraceAPI.prototype.getTracerProvider = function () {
    return getGlobal(API_NAME) || this._proxyTracerProvider;
  };
  /**
   * Returns a tracer from the global tracer provider.
   */
  TraceAPI.prototype.getTracer = function (name, version) {
    return this.getTracerProvider().getTracer(name, version);
  };
  /** Remove the global tracer provider */
  TraceAPI.prototype.disable = function () {
    unregisterGlobal(API_NAME, DiagAPI.instance());
    this._proxyTracerProvider = new ProxyTracerProvider();
  };
  return TraceAPI;
}();
export { TraceAPI };
