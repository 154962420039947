import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import {
    provideRouter,
    withComponentInputBinding,
    withEnabledBlockingInitialNavigation,
    withInMemoryScrolling,
    withRouterConfig,
} from '@angular/router';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { of } from 'rxjs';

import { NavigationActionTiming, provideRouterStore } from '@ngrx/router-store';

import { provideAzureCore } from '@mobi/rwc-utils-azure-ng-jslib';

import { Language, LANGUAGE_PROVIDER, LanguageProvider, SPAConfiguration } from '@mobi/rwc-utils-ng-jslib';

import { provideTealiumTracking, TealiumConfigLoader, TealiumConfigStaticLoader } from '@mobi/tea-tagmanager-ng-jslib';

import { ErrorInterceptorService } from '@mobi/oiv-viewer-ng-jslib';

import { appRoutes } from './app.routes';

class CustomLanguageProvider implements LanguageProvider {
    getLanguage() {
        return of(localStorage.getItem('settings.language') as Language);
    }
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimationsAsync(),
        provideHttpClient(withInterceptorsFromDi()),
        provideZoneChangeDetection({
            eventCoalescing: true,
            runCoalescing: true,
        }),
        provideRouter(
            appRoutes,
            withRouterConfig({
                onSameUrlNavigation: 'reload',
            }),
            withComponentInputBinding(),
            withEnabledBlockingInitialNavigation(),
            withInMemoryScrolling({
                anchorScrolling: 'enabled',
                scrollPositionRestoration: 'top',
            }),
        ),
        provideAzureCore(),
        provideStore(),
        provideRouterStore({
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
        provideStoreDevtools(),
        provideTealiumTracking({
            configLoader: {
                provide: TealiumConfigLoader,
                useFactory: tealiumConfigLoaderFactory,
            },
        }),
        {
            provide: LANGUAGE_PROVIDER,
            useClass: CustomLanguageProvider,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptorService,
            multi: true,
        },
    ],
};

function tealiumConfigLoaderFactory(): TealiumConfigLoader {
    const RWC_CONFIG: SPAConfiguration = (window.document.defaultView as Window).MOBI_RWC_CONFIG;
    return new TealiumConfigStaticLoader({
        account: 'mobiliar',
        profile: 'oiv',
        environment: RWC_CONFIG.stage === 'prod' ? 'prod' : 'dev',
    });
}
