/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { NoopContextManager } from '../context/NoopContextManager';
import { getGlobal, registerGlobal, unregisterGlobal } from '../internal/global-utils';
import { DiagAPI } from './diag';
var API_NAME = 'context';
var NOOP_CONTEXT_MANAGER = new NoopContextManager();
/**
 * Singleton object which represents the entry point to the OpenTelemetry Context API
 */
var ContextAPI = /** @class */function () {
  /** Empty private constructor prevents end users from constructing a new instance of the API */
  function ContextAPI() {}
  /** Get the singleton instance of the Context API */
  ContextAPI.getInstance = function () {
    if (!this._instance) {
      this._instance = new ContextAPI();
    }
    return this._instance;
  };
  /**
   * Set the current context manager.
   *
   * @returns true if the context manager was successfully registered, else false
   */
  ContextAPI.prototype.setGlobalContextManager = function (contextManager) {
    return registerGlobal(API_NAME, contextManager, DiagAPI.instance());
  };
  /**
   * Get the currently active context
   */
  ContextAPI.prototype.active = function () {
    return this._getContextManager().active();
  };
  /**
   * Execute a function with an active context
   *
   * @param context context to be active during function execution
   * @param fn function to execute in a context
   * @param thisArg optional receiver to be used for calling fn
   * @param args optional arguments forwarded to fn
   */
  ContextAPI.prototype.with = function (context, fn, thisArg) {
    var _a;
    var args = [];
    for (var _i = 3; _i < arguments.length; _i++) {
      args[_i - 3] = arguments[_i];
    }
    return (_a = this._getContextManager()).with.apply(_a, __spreadArray([context, fn, thisArg], __read(args), false));
  };
  /**
   * Bind a context to a target function or event emitter
   *
   * @param context context to bind to the event emitter or function. Defaults to the currently active context
   * @param target function or event emitter to bind
   */
  ContextAPI.prototype.bind = function (context, target) {
    return this._getContextManager().bind(context, target);
  };
  ContextAPI.prototype._getContextManager = function () {
    return getGlobal(API_NAME) || NOOP_CONTEXT_MANAGER;
  };
  /** Disable and remove the global context manager */
  ContextAPI.prototype.disable = function () {
    this._getContextManager().disable();
    unregisterGlobal(API_NAME, DiagAPI.instance());
  };
  return ContextAPI;
}();
export { ContextAPI };
