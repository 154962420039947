/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var Deferred = /** @class */function () {
  function Deferred() {
    var _this = this;
    this._promise = new Promise(function (resolve, reject) {
      _this._resolve = resolve;
      _this._reject = reject;
    });
  }
  Object.defineProperty(Deferred.prototype, "promise", {
    get: function () {
      return this._promise;
    },
    enumerable: false,
    configurable: true
  });
  Deferred.prototype.resolve = function (val) {
    this._resolve(val);
  };
  Deferred.prototype.reject = function (err) {
    this._reject(err);
  };
  return Deferred;
}();
export { Deferred };
