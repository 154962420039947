/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { NoopTracer } from './NoopTracer';
var NOOP_TRACER = new NoopTracer();
/**
 * Proxy tracer provided by the proxy tracer provider
 */
var ProxyTracer = /** @class */function () {
  function ProxyTracer(_provider, name, version, options) {
    this._provider = _provider;
    this.name = name;
    this.version = version;
    this.options = options;
  }
  ProxyTracer.prototype.startSpan = function (name, options, context) {
    return this._getTracer().startSpan(name, options, context);
  };
  ProxyTracer.prototype.startActiveSpan = function (_name, _options, _context, _fn) {
    var tracer = this._getTracer();
    return Reflect.apply(tracer.startActiveSpan, tracer, arguments);
  };
  /**
   * Try to get a tracer from the proxy tracer provider.
   * If the proxy tracer provider has no delegate, return a noop tracer.
   */
  ProxyTracer.prototype._getTracer = function () {
    if (this._delegate) {
      return this._delegate;
    }
    var tracer = this._provider.getDelegateTracer(this.name, this.version, this.options);
    if (!tracer) {
      return NOOP_TRACER;
    }
    this._delegate = tracer;
    return this._delegate;
  };
  return ProxyTracer;
}();
export { ProxyTracer };
