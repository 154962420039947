import { ChangeDetectionStrategy, Component, output } from '@angular/core';

import {
    MobiUiFooterComponent,
    MobiUiLanguage,
    MobiUiOverlayComponent,
    MobiUiOverlayDirective,
} from '@mobi/rwc-ui-components-ng-jslib';
import { TranslatePipe } from '@mobi/rwc-utils-ng-jslib';
import { TealiumTrackLinkOffsiteDirective, TealiumTrackLinkOnsiteDirective } from '@mobi/tea-tagmanager-ng-jslib';

@Component({
    standalone: true,
    selector: 'oiv-viewer-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [
        MobiUiFooterComponent,
        TranslatePipe,
        MobiUiOverlayDirective,
        MobiUiOverlayComponent,
        TealiumTrackLinkOnsiteDirective,
        TealiumTrackLinkOffsiteDirective,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
    languageChange = output<MobiUiLanguage>();

    onChange(language: MobiUiLanguage) {
        this.languageChange.emit(language);
    }
}
