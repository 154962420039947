/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
/**
 * No-op implementations of {@link TextMapPropagator}.
 */
var NoopTextMapPropagator = /** @class */function () {
  function NoopTextMapPropagator() {}
  /** Noop inject function does nothing */
  NoopTextMapPropagator.prototype.inject = function (_context, _carrier) {};
  /** Noop extract function does nothing and returns the input context */
  NoopTextMapPropagator.prototype.extract = function (context, _carrier) {
    return context;
  };
  NoopTextMapPropagator.prototype.fields = function () {
    return [];
  };
  return NoopTextMapPropagator;
}();
export { NoopTextMapPropagator };
