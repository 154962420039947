import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, input, output } from '@angular/core';
import { NgClass } from '@angular/common';

import {
    MOBI_UI_HEADER_LOGO,
    MobiUiHeaderComponent,
    MobiUiIconComponent,
    MobiUiIconsRegistry,
} from '@mobi/rwc-ui-components-ng-jslib';
import { MobiIcon, mobiIconMenu, mobiIconTheX } from '@mobi/rwc-utils-icons-jslib';

export interface HeaderIcon {
    icon: MobiIcon;
    isDisabled: boolean;
    className?: string[];
}

@Component({
    standalone: true,
    selector: 'oiv-viewer-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [MobiUiIconComponent, MobiUiHeaderComponent, NgClass],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    iconClick = output<HeaderIcon>();
    logoClick = output<void>();
    isDisplayIcon = input<boolean>(false);

    readonly HEADER_LOGO = MOBI_UI_HEADER_LOGO;

    iconMenu: HeaderIcon = { icon: mobiIconMenu, isDisabled: false };
    iconTheX: HeaderIcon = { icon: mobiIconTheX, isDisabled: true };

    constructor(private cdr: ChangeDetectorRef) {
        inject(MobiUiIconsRegistry).registerIcons([mobiIconTheX, mobiIconMenu]);
    }

    toggleIcons(icon: HeaderIcon) {
        if (icon.icon.name === mobiIconMenu.name) {
            this.enableCloseIcon();
        } else if (icon.icon.name === mobiIconTheX.name) {
            this.enableMenuIcon();
        }
        this.iconClick.emit(icon);
    }

    navigateToHomePage() {
        this.logoClick.emit();
    }

    enableMenuIcon() {
        this.iconMenu = { ...this.iconMenu, isDisabled: false };
        this.iconTheX = { ...this.iconTheX, isDisabled: true };
        this.cdr.detectChanges();
    }

    enableCloseIcon() {
        this.iconMenu = { ...this.iconMenu, isDisabled: true };
        this.iconTheX = { ...this.iconTheX, isDisabled: false };
        this.cdr.detectChanges();
    }
}
