/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var consoleMap = [{
  n: 'error',
  c: 'error'
}, {
  n: 'warn',
  c: 'warn'
}, {
  n: 'info',
  c: 'info'
}, {
  n: 'debug',
  c: 'debug'
}, {
  n: 'verbose',
  c: 'trace'
}];
/**
 * A simple Immutable Console based diagnostic logger which will output any messages to the Console.
 * If you want to limit the amount of logging to a specific level or lower use the
 * {@link createLogLevelDiagLogger}
 */
var DiagConsoleLogger = /** @class */function () {
  function DiagConsoleLogger() {
    function _consoleFunc(funcName) {
      return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          args[_i] = arguments[_i];
        }
        if (console) {
          // Some environments only expose the console when the F12 developer console is open
          // eslint-disable-next-line no-console
          var theFunc = console[funcName];
          if (typeof theFunc !== 'function') {
            // Not all environments support all functions
            // eslint-disable-next-line no-console
            theFunc = console.log;
          }
          // One last final check
          if (typeof theFunc === 'function') {
            return theFunc.apply(console, args);
          }
        }
      };
    }
    for (var i = 0; i < consoleMap.length; i++) {
      this[consoleMap[i].n] = _consoleFunc(consoleMap[i].c);
    }
  }
  return DiagConsoleLogger;
}();
export { DiagConsoleLogger };
