/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __values = this && this.__values || function (o) {
  var s = typeof Symbol === "function" && Symbol.iterator,
    m = s && o[s],
    i = 0;
  if (m) return m.call(o);
  if (o && typeof o.length === "number") return {
    next: function () {
      if (o && i >= o.length) o = void 0;
      return {
        value: o && o[i++],
        done: !o
      };
    }
  };
  throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var BaggageImpl = /** @class */function () {
  function BaggageImpl(entries) {
    this._entries = entries ? new Map(entries) : new Map();
  }
  BaggageImpl.prototype.getEntry = function (key) {
    var entry = this._entries.get(key);
    if (!entry) {
      return undefined;
    }
    return Object.assign({}, entry);
  };
  BaggageImpl.prototype.getAllEntries = function () {
    return Array.from(this._entries.entries()).map(function (_a) {
      var _b = __read(_a, 2),
        k = _b[0],
        v = _b[1];
      return [k, v];
    });
  };
  BaggageImpl.prototype.setEntry = function (key, entry) {
    var newBaggage = new BaggageImpl(this._entries);
    newBaggage._entries.set(key, entry);
    return newBaggage;
  };
  BaggageImpl.prototype.removeEntry = function (key) {
    var newBaggage = new BaggageImpl(this._entries);
    newBaggage._entries.delete(key);
    return newBaggage;
  };
  BaggageImpl.prototype.removeEntries = function () {
    var e_1, _a;
    var keys = [];
    for (var _i = 0; _i < arguments.length; _i++) {
      keys[_i] = arguments[_i];
    }
    var newBaggage = new BaggageImpl(this._entries);
    try {
      for (var keys_1 = __values(keys), keys_1_1 = keys_1.next(); !keys_1_1.done; keys_1_1 = keys_1.next()) {
        var key = keys_1_1.value;
        newBaggage._entries.delete(key);
      }
    } catch (e_1_1) {
      e_1 = {
        error: e_1_1
      };
    } finally {
      try {
        if (keys_1_1 && !keys_1_1.done && (_a = keys_1.return)) _a.call(keys_1);
      } finally {
        if (e_1) throw e_1.error;
      }
    }
    return newBaggage;
  };
  BaggageImpl.prototype.clear = function () {
    return new BaggageImpl();
  };
  return BaggageImpl;
}();
export { BaggageImpl };
