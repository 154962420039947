<div id="oiv-footer-wrapper" class="mobi-ui-wrapper-footer oiv-footer-wrapper">
    <mobi-ui-footer-major (languageChange)="onChange($event)">
        <a
            href="{{ 'oiv.footer.dataprotection.link' | translate }}"
            mobiTealiumTrackLinkOffsite="dataProtection"
            trackingLinkCategory="OffSiteLink - Footer"
            trackingLinkAction="click"
            [trackingLinkTarget]="'oiv.footer.dataprotection.link' | translate"
            target="_blank"
        >
            {{ 'oiv.footer.dataProtection' | translate }}
        </a>
        <a
            class="oiv-pointer"
            [mobiUiOverlay]="componentContentOverlay"
            mobiTealiumTrackLinkOnsite="instruction"
            trackingLinkCategory="OnSiteLink - Footer"
            trackingLinkAction="click"
        >
            {{ 'oiv.footer.instruction' | translate }}
        </a>
        <a
            href="{{ 'oiv.footer.imprint.link' | translate }}"
            target="_blank"
            mobiTealiumTrackLinkOffsite="imprint"
            trackingLinkCategory="OffSiteLink - Footer"
            trackingLinkAction="click"
            [trackingLinkTarget]="'oiv.footer.imprint.link' | translate"
        >
            {{ 'oiv.footer.imprint' | translate }}
        </a>
        <a
            href="{{ 'oiv.footer.legalInfo.link' | translate }}"
            target="_blank"
            mobiTealiumTrackLinkOffsite="legalInfo"
            trackingLinkCategory="OffSiteLink - Footer"
            trackingLinkAction="click"
            trackingLinkTarget="{{ 'oiv.footer.legalInfo.link' | translate }}"
        >
            {{ 'oiv.footer.legalInfo' | translate }}
        </a>
        <a
            href="{{ 'oiv.footer.contactAndFeedback.link' | translate }}"
            target="_blank"
            mobiTealiumTrackLinkOffsite="contactAndFeedback"
            trackingLinkCategory="OffSiteLink - Footer"
            trackingLinkAction="click"
            trackingLinkTarget="{{ 'oiv.footer.contactAndFeedback.link' | translate }}"
        >
            {{ 'oiv.footer.contactAndFeedback' | translate }}
        </a>
    </mobi-ui-footer-major>
</div>

<ng-template #componentContentOverlay let-overlay>
    <mobi-ui-overlay-major
        class="oiv-overlay-custom is-full-size-content"
        [overlay]="overlay"
        [size]="'md'"
        [showCloseOverlayButton]="false"
    >
        <div class="mobi-ui-section-minor mobi-ui-ink-grey">
            <h3 class="mobi-ui-text-center mobi-ui-ink-default">{{ 'oiv.footer.instruction' | translate }}</h3>
        </div>
        <div class="mobi-ui-margin-x-xl">
            <p class="mobi-ui-padding-t-lg">{{ 'oiv.footer.instruction.p1' | translate }}</p>
            <p class="mobi-ui-padding-t-lg">{{ 'oiv.footer.instruction.p2' | translate }}</p>
            <p class="mobi-ui-padding-t-lg">{{ 'oiv.footer.instruction.p3' | translate }}</p>
            <div class="mobi-ui-text-center mobi-ui-padding-y-lg">
                <button type="button" class="mobi-ui-btn-minor" (click)="overlay.close()">
                    {{ 'oiv.button.close' | translate }}
                </button>
            </div>
        </div>
    </mobi-ui-overlay-major>
</ng-template>
