/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { ProxyTracer } from './ProxyTracer';
import { NoopTracerProvider } from './NoopTracerProvider';
var NOOP_TRACER_PROVIDER = new NoopTracerProvider();
/**
 * Tracer provider which provides {@link ProxyTracer}s.
 *
 * Before a delegate is set, tracers provided are NoOp.
 *   When a delegate is set, traces are provided from the delegate.
 *   When a delegate is set after tracers have already been provided,
 *   all tracers already provided will use the provided delegate implementation.
 */
var ProxyTracerProvider = /** @class */function () {
  function ProxyTracerProvider() {}
  /**
   * Get a {@link ProxyTracer}
   */
  ProxyTracerProvider.prototype.getTracer = function (name, version, options) {
    var _a;
    return (_a = this.getDelegateTracer(name, version, options)) !== null && _a !== void 0 ? _a : new ProxyTracer(this, name, version, options);
  };
  ProxyTracerProvider.prototype.getDelegate = function () {
    var _a;
    return (_a = this._delegate) !== null && _a !== void 0 ? _a : NOOP_TRACER_PROVIDER;
  };
  /**
   * Set the delegate tracer provider
   */
  ProxyTracerProvider.prototype.setDelegate = function (delegate) {
    this._delegate = delegate;
  };
  ProxyTracerProvider.prototype.getDelegateTracer = function (name, version, options) {
    var _a;
    return (_a = this._delegate) === null || _a === void 0 ? void 0 : _a.getTracer(name, version, options);
  };
  return ProxyTracerProvider;
}();
export { ProxyTracerProvider };
