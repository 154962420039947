/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
var __spreadArray = this && this.__spreadArray || function (to, from, pack) {
  if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
    if (ar || !(i in from)) {
      if (!ar) ar = Array.prototype.slice.call(from, 0, i);
      ar[i] = from[i];
    }
  }
  return to.concat(ar || Array.prototype.slice.call(from));
};
import { DiagComponentLogger } from '../diag/ComponentLogger';
import { createLogLevelDiagLogger } from '../diag/internal/logLevelLogger';
import { DiagLogLevel } from '../diag/types';
import { getGlobal, registerGlobal, unregisterGlobal } from '../internal/global-utils';
var API_NAME = 'diag';
/**
 * Singleton object which represents the entry point to the OpenTelemetry internal
 * diagnostic API
 */
var DiagAPI = /** @class */function () {
  /**
   * Private internal constructor
   * @private
   */
  function DiagAPI() {
    function _logProxy(funcName) {
      return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
          args[_i] = arguments[_i];
        }
        var logger = getGlobal('diag');
        // shortcut if logger not set
        if (!logger) return;
        return logger[funcName].apply(logger, __spreadArray([], __read(args), false));
      };
    }
    // Using self local variable for minification purposes as 'this' cannot be minified
    var self = this;
    // DiagAPI specific functions
    var setLogger = function (logger, optionsOrLogLevel) {
      var _a, _b, _c;
      if (optionsOrLogLevel === void 0) {
        optionsOrLogLevel = {
          logLevel: DiagLogLevel.INFO
        };
      }
      if (logger === self) {
        // There isn't much we can do here.
        // Logging to the console might break the user application.
        // Try to log to self. If a logger was previously registered it will receive the log.
        var err = new Error('Cannot use diag as the logger for itself. Please use a DiagLogger implementation like ConsoleDiagLogger or a custom implementation');
        self.error((_a = err.stack) !== null && _a !== void 0 ? _a : err.message);
        return false;
      }
      if (typeof optionsOrLogLevel === 'number') {
        optionsOrLogLevel = {
          logLevel: optionsOrLogLevel
        };
      }
      var oldLogger = getGlobal('diag');
      var newLogger = createLogLevelDiagLogger((_b = optionsOrLogLevel.logLevel) !== null && _b !== void 0 ? _b : DiagLogLevel.INFO, logger);
      // There already is an logger registered. We'll let it know before overwriting it.
      if (oldLogger && !optionsOrLogLevel.suppressOverrideMessage) {
        var stack = (_c = new Error().stack) !== null && _c !== void 0 ? _c : '<failed to generate stacktrace>';
        oldLogger.warn("Current logger will be overwritten from " + stack);
        newLogger.warn("Current logger will overwrite one already registered from " + stack);
      }
      return registerGlobal('diag', newLogger, self, true);
    };
    self.setLogger = setLogger;
    self.disable = function () {
      unregisterGlobal(API_NAME, self);
    };
    self.createComponentLogger = function (options) {
      return new DiagComponentLogger(options);
    };
    self.verbose = _logProxy('verbose');
    self.debug = _logProxy('debug');
    self.info = _logProxy('info');
    self.warn = _logProxy('warn');
    self.error = _logProxy('error');
  }
  /** Get the singleton instance of the DiagAPI API */
  DiagAPI.instance = function () {
    if (!this._instance) {
      this._instance = new DiagAPI();
    }
    return this._instance;
  };
  return DiagAPI;
}();
export { DiagAPI };
