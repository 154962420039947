/*
 * Copyright The OpenTelemetry Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var MAX_ATTEMPTS = 5;
var INITIAL_BACKOFF = 1000;
var MAX_BACKOFF = 5000;
var BACKOFF_MULTIPLIER = 1.5;
var JITTER = 0.2;
/**
 * Get a pseudo-random jitter that falls in the range of [-JITTER, +JITTER]
 */
function getJitter() {
  return Math.random() * (2 * JITTER) - JITTER;
}
var RetryingTransport = /** @class */function () {
  function RetryingTransport(_transport) {
    this._transport = _transport;
  }
  RetryingTransport.prototype.retry = function (data, timeoutMillis, inMillis) {
    var _this = this;
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        _this._transport.send(data, timeoutMillis).then(resolve, reject);
      }, inMillis);
    });
  };
  RetryingTransport.prototype.send = function (data, timeoutMillis) {
    var _a;
    return __awaiter(this, void 0, void 0, function () {
      var deadline, result, attempts, nextBackoff, backoff, retryInMillis, remainingTimeoutMillis;
      return __generator(this, function (_b) {
        switch (_b.label) {
          case 0:
            deadline = Date.now() + timeoutMillis;
            return [4 /*yield*/, this._transport.send(data, timeoutMillis)];
          case 1:
            result = _b.sent();
            attempts = MAX_ATTEMPTS;
            nextBackoff = INITIAL_BACKOFF;
            _b.label = 2;
          case 2:
            if (!(result.status === 'retryable' && attempts > 0)) return [3 /*break*/, 4];
            attempts--;
            backoff = Math.max(Math.min(nextBackoff, MAX_BACKOFF) + getJitter(), 0);
            nextBackoff = nextBackoff * BACKOFF_MULTIPLIER;
            retryInMillis = (_a = result.retryInMillis) !== null && _a !== void 0 ? _a : backoff;
            remainingTimeoutMillis = deadline - Date.now();
            if (retryInMillis > remainingTimeoutMillis) {
              return [2 /*return*/, result];
            }
            return [4 /*yield*/, this.retry(data, remainingTimeoutMillis, retryInMillis)];
          case 3:
            result = _b.sent();
            return [3 /*break*/, 2];
          case 4:
            return [2 /*return*/, result];
        }
      });
    });
  };
  RetryingTransport.prototype.shutdown = function () {
    return this._transport.shutdown();
  };
  return RetryingTransport;
}();
/**
 * Creates an Exporter Transport that retries on 'retryable' response.
 */
export function createRetryingTransport(options) {
  return new RetryingTransport(options.transport);
}
